import React, { useEffect } from 'react';
import { object } from 'prop-types';
import safeNavigate from 'apputil/safe-navigate';

import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { SkeletonHomeView } from 'views/home/skeleton';
import queryString from 'query-string';

import { isAuthenticated } from 'services/authentication-service';
import { useAuth0 } from '@auth0/auth0-react';

import { useStateContext } from 'contexts/state-context';
import { useInitLoadContext } from 'contexts/init-load-context';
import { useNoticationContext } from 'contexts/notification-context';
import { removeUser } from 'apputil/user';
import { AUTH_DOMAIN } from 'gatsby-env-variables';
import { LANDING_PAGE } from 'constants/navigation';

const LoginCallback = ({ location = {} }) => {
  const logger = buildLogger();
  const { hash = '' } = location;
  const { error = '', error_description = '' } = queryString.parse(hash) || {};

  const stateContext = useStateContext();
  const initLoadContext = useInitLoadContext();
  const { showNotification, types } = useNoticationContext();
  const { user, getAccessTokenSilently } = useAuth0();

  const authenticated = isAuthenticated();

  useEffect(() => {
    const tryLoginCallback = async () => {
      try {
        if (error) {
          const getLogoutService = await import('services/login-logout/logout-service');
          const { forceLogout } = getLogoutService.default({ stateContext });
          await forceLogout({ error, error_description });
        } else {
          const getHandleAuthService = await import('services/login-logout/handle-auth-service');
          const { handleAuth } = getHandleAuthService.default({ stateContext, initLoadContext });
          const token = await getAccessTokenSilently({
            audience: `https://${AUTH_DOMAIN}/api/v2/`,
            scope: 'read:current_user',
          });
          const { sub } = user;
          await handleAuth({ token, sub });
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'tryLoginCallback:onError' },
          message,
          trace,
        }));
        removeUser();
        showNotification({
          message: 'There has been issue with login please try again.',
          type: types.error,
        });
        safeNavigate(LANDING_PAGE);
      }
    };
    if (!authenticated && user && user.sub) tryLoginCallback();

    // eslint-disable-next-line
  }, [user]);

  return (<SkeletonHomeView />);
};

LoginCallback.propTypes = { location: object };

export default LoginCallback;
